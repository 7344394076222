// COMPOSABLE
// IMPORT LIBRERIE VARIE
import { ref, toValue } from "vue";
import { requestAppPost, requestAppPostNoAlert } from "@/db/dbManag";

export function useApiCall(nomeApi, payload, showAlertOnError = false) {
  const element = ref(null);
  const loaded = ref(false);
  const status = ref(0);
  const getElement = async () => {
    loaded.value = false;
    element.value = null;
    if (showAlertOnError) {
      await requestAppPost(nomeApi, toValue(payload)).then((res) => {
        if (res.status == 200) {
          element.value = res.data;
        }
        loaded.value = true;
        status.value = res.status;
      });
    } else {
      await requestAppPostNoAlert(nomeApi, toValue(payload)).then((res) => {
        if (res.status == 200) {
          element.value = res.data;
        }
        loaded.value = true;
        status.value = res.status;
      });
    }
  };

  return {
    element,
    loaded,
    status,
    getElement,
  };
}
